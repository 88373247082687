import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import SEO from "../components/SEO";
import ContactForm from '../components/ContactForm'
import AdvancedSettings from "../settings/advanced_settings.json";
import Img from "gatsby-image";
import StarsRatingComponent from "../components/StarsRatingsComponent";
import HowItWorks from "../components/HowItWorks";
import AgentvsSellFastSactable from '../components/AgentvsSellFastSacTable'
import { CameraIcon } from '@heroicons/react/solid'
import SeeTheDifferenceSidebar from '../components/SeeTheDifferenceSidebar'


export const IndexPageTemplate = ({
  image,
  title,
  carousel,
  featuredservices,
  maincontentblocks,
  heading,
  subheading,
  description,
  intro,
  metatitle,
  metadescription,
  metakeywords,
  headerTransparent,
  headerImage,
  teamImage

}) => {
  const headerOffsetTransparent = 95;
  const FORMOFFSETPX = 350;


  return (
    <>
      <SEO
        title={'Sell My House Fast In Sacramento, CA - We Buy Houses'}
        description={'Need to sell your house fast? We buy houses in Sacramento, CA in as little as 7 days. If youre saying I need to sell my house fast!, wed like to buy!'}
        keywords={metakeywords.join(", ")}
      />

      <div
        className="overflow-hidden"
        style={{
          marginTop: "0",
        }}
      >
        <div className="h-auto bg-cover relative bg-black ">
          <Img
            fluid={headerImage.childImageSharp.fluid}
            className="hero-image absolute top-0 h-full"
            style={{ opacity: "1", transform: "none" }}
          />

          <div
            className="relative"
            style={{
              background:
                "linear-gradient(0deg, transparent, rgb(0 0 0/ 10%))",
              paddingTop: "0",
            }}
          >
            <div
              className={`container py-8 carousel-content-container flex flex-wrap justify-between relative mx-auto px-4 md:px-6`}
            >
              <div className="w-full md:w-1/2 lg:w-2/3 py-12 md:pr-8 lg:pr-32">
                <div>
                  <h1 className="text-shadow-lg text-center md:text-left text-white text-shadow service-heading-tag relative mb-2 text-3xl md:text-4xl font-bold leading-10">
                    Sell Your House Fast In Sacramento, CA<br />
                 
                  </h1>
                  <p className="text-shadow-lg text-center md:text-left text-white text-shadow font-display text-base md:text-2xl mt-1 block">No Commissions, Repairs, or Waiting</p>
                  <p className="text-shadow-md text-center md:text-left text-white mt-1 text-base leading-7 ">
                    Reach out to us to get a great cash offer and close on the day of your choice! We are not realtors, we are investors and problem solvers who can buy your Sacramento house or land fast, with a fair, all cash offer.


                  </p>

                </div>
                <div className="lg:py-3">
                  <StarsRatingComponent
                    centerOnMobile={true}
                    className={"mt-8"}
                    reviewavg={AdvancedSettings.reviewavg}
                    reviewscount={AdvancedSettings.reviewscount}
                    enabled={AdvancedSettings.ratingstarsdisplayenabled}
                  />
                </div>

              </div>
              <div
                className="w-full md:w-1/2 lg:w-1/3 form-offset"
              // style={{ marginBottom: FORMOFFSETPX * -1 + "px" }}
              >
                <div className="bg-white rounded-xl p-8 pb-6 md-mb-none shadow-xl relative border-t-4 border-accent-600">

                  <div className="mb-4">
                    <h3 className="font-display leading-6 md:leading-7 font-bold text-2xl text-gray-800">
                      Get Your Fair Cash Offer: Start Below!

                </h3>
                    <p className="text-gray-600 mb-2 leading-6 mt-1">
                      We buy houses in <strong>any condition</strong> -- no commissions or fees and no obligation whatsoever.
                </p>
                  </div>
                  <ContactForm hideLabels={true} showPlaceholders={true} footerContained={true} stacked={true} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{ height: FORMOFFSETPX + "px" }}
          className="height-none-md"
        />
      </div>




      <HowItWorks />

      <AgentvsSellFastSactable />


      <section className="py-16 section-stripe-bg bg-gray-50 ">
        <div className="flex flex-wrap px-6 md:px-8 container mx-auto ">
          <div className="w-full md:w-2/3 md:pr-4 prose">
            <h1 className="text-3xl text-gray-900 font-extrabold tracking-tight sm:text-4xl mt-2 mb-2">We Buy Houses and Land in Sacramento!</h1>

            <h3 className="text-base text-accent-600 font-semibold mt-0 font-display">Find Out If A Direct Offer Is The Right Choice For You!</h3>

            <p className="text-gray-700">At Sell Your Home For Cash Sacramento, we will offer you a simple and quick selling process. You should not have to wait months and months for banks, contractors, or real estate agents if you want to sell your house or your land.</p>

            <p><a href="https://www.sellingyourhomesacramento.com/how-we-buy-houses/">We buy houses</a> and land directly from homeowners who want to sell now rather than later. Our process makes selling simple and convenient, no matter the condition or situation you are in. Before you call a Sacramento real estate agent, reach out to our team to explore the benefits we can offer you!</p>



            <h3 className="has-text-align-center">A Little About Us</h3>

            <Img
              className="rounded-lg shadow-lg object-cover object-center"
              fluid={teamImage.childImageSharp.fluid}
            />
            <figcaption class="mt-3 flex text-sm text-gray-500">

              <CameraIcon className="flex-none w-5 h-5 text-gray-400" />
              <span class="ml-2">Sell Your Home For Cash Sacramento Team</span>
            </figcaption>

            <p>Hi, my name is Brandon Evans, and I am the owner of this local business. I have years of experience purchasing houses that need love in the Sacramento region. I’m proud to work with a team of local, experienced, and easy to work with professionals. We pride ourselves in being polite and accommodating to your needs as a seller. We partner with a team of licensed contractors in the area and few jobs are too overwhelming for us. Click below to learn more about how we can serve you!</p>





            <h2>How Do I Sell My House Fast In Sacramento?
</h2>



            <p>After 15 years in the real estate industry, we have seen it all. We know exactly how to help with any difficult property or situation so you can sell your house fast and move on. </p>



            <ul><li><span><a href="https://www.sellingyourhomesacramento.com/avoiding-foreclosure/">Do you need to avoid foreclosure?</a> </span></li><li><span>Are you behind in your mortgage payments? </span></li><li><span>Have you recently gotten a divorce? </span></li><li><span>Do you want to buy a house elsewhere? </span></li><li><span>Have you inherited a property that you are not going to keep? </span></li><li><span>Do you own a vacant house?</span></li><li>Are you dealing with bad tenants?</li></ul>



            <p><span>No matter why you need to sell your house in Sacramento, our team will make it easy for you. You don’t have to feel stuck with a house you do not want. Reach out to our team today to get a straightforward, no-obligation offer of your own. </span></p>






            <h2><span>We Buy Houses & Land Fast in Sacramento CA</span></h2>



            <p><b>Hiring a real estate agent in Sacramento is not the only way to sell your house or land. </b>While the process works for some people, for others it can be expensive and time-consuming. <span>Agents will typically lock you into an agreement without any guarantee that your house will sell.  With a direct sale to Sell Your Home For Cash Sacramento, you will get the peace of mind you need. We can close on your house or your land in only 7 short days. </span>Plus, our team offers a number of great benefits that an agent can’t compete with. </p>



            <p>For example… </p>



            <ul><li>You will not have to clean or make repairs</li><li>You will not have to stage or show the house</li><li>You will not have to wait around for a buyer</li><li>You will not have to worry about the sale falling through</li><li>You will not have to pay expensive agent commission</li><li>You will be able to end your holding costs right away</li><li>You will be able to plan ahead</li><li>You will never have to worry about the sale falling through</li></ul>



            <p><span>You could try to sell it yourself without using a real estate agent, but that process can be very expensive and stressful. You’ll have to find a buyer on your own, and you’ll have to pay for advertising and screen calls. For may homeowners, an FSBO listing isn’t worth it. </span></p>





            <h3 className="has-text-align-center"><span>Skip Listing Your House In Sacramento And Sell Directly To Our Team!</span></h3>



            <p>Our process makes it easy to sell your house in Sacramento without all of the costs, stress, and waiting found when using an agent. <span>Plus, <a href="https://www.sellingyourhomesacramento.com/how-we-buy-houses/">we buy houses in any condition.</a> Even if your house has fire damage or a leaking roof or other expensive repairs, we will still give you a fair offer.&nbsp;</span></p>



            <p><span>Most banks will not lend money on houses that need major repairs. But we don’t need banks. We have our own funds and we can close in just 7 days. In fact, we will provide you with a $5,000, non-refundable deposit so that you know we are serious. </span></p>



            <p>To learn more about us, our process, and what we can offer you, fill out the form below or call the office directly. There is never any hassle or obligation. <strong>916-237-9457 </strong></p>





            <p className="has-text-align-center"><strong>Or Give Us A Call Now At:&nbsp;916-237-9457</strong></p>



            <p><span>We help many <a href="http://www.cityofsacramento.org">Sacramento</a> homeowners who are facing tough situations such as foreclosure, divorce, the loss of a family member, difficult renters, repairs, or a probate situation. No matter what you are up against, we will work with you to find the best solution for your situation. Reach out to us today to learn more! </span></p>



            <p><span><em><strong>We buy houses and land in Sacramento, CA and the surrounding areas. </strong>If you have said, “I need to sell my house fast,” our team will do everything we can to help you out of your current situation. Reach out to us today to get an offer of your own. </em></span></p>


          </div>
          <div className="w-full md:w-1/3 md:pl-4">

          <SeeTheDifferenceSidebar/>

            <div className="rounded-xl mb-2 shadow bg-white  mt-6 md:mt-0">
              <div className="px-8 pt-8">
                <h2 className="text-gray-800 font-bold text-2xl md:text-3xl leading-8">Get Your Fair Cash Offer: Start Below!</h2>
                <p class="text-gray-600 mb-2 leading-6 mt-1">We buy houses in any condition -- no commissions or fees and no obligation whatsoever.</p>
              </div>


              <div className="p-8 pt-4"><ContactForm stacked={true} hideLabels={true} showPlaceholders={true} /></div>

            </div>
          </div>
        </div>
      </section>



    </>
  );
};

IndexPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  carousel: PropTypes.array,
  featuredservices: PropTypes.array,
  maincontentblocks: PropTypes.array,
};

const IndexPage = ({ data }) => {
  const { headerImage, teamImage } = data;

  const { frontmatter } = data.markdownRemark;

  return (
    <Layout headerTransparent={false} isHomePage={true}>
      <IndexPageTemplate
        metatitle={frontmatter.metatitle}
        metadescription={frontmatter.metadescription}
        metakeywords={frontmatter.metakeywords}
        image={frontmatter.image}
        title={frontmatter.title}
        carousel={frontmatter.carousel}
        featuredservices={frontmatter.featuredservices}
        maincontentblocks={frontmatter.maincontentblocks}
        headerTransparent={false}
        headerImage={headerImage}
        teamImage={teamImage}

      />
    </Layout>
  );
};

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default IndexPage;

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        metatitle
        metadescription
        metakeywords
        carousel {
          title
          image {
            childImageSharp {
              fluid(maxWidth: 1366, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          buttontext
          description
          buttonlink
        }
        featuredservices {
          title
          description
          buttontext
          buttonlink
          image {
            childImageSharp {
              fluid(maxWidth: 300, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        maincontentblocks {
          type
          label
          title
          image {
            childImageSharp {
              fluid(maxWidth: 1200, quality: 80) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          value
          description
          footerlogoshowcase {
            frontmatter {
              title
              images {
                imgtitle
                image {
                  publicURL
                  extension
                  childImageSharp {
                    fluid(maxHeight: 250, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
          }
          contentwithsidebarsidebar {
            sidebar {
              frontmatter {
                title
                bodycontent
                dynamicsidebarid
                renderashtml
                outercontainercss
                id
              }
            }
            sidebarcollection {
              frontmatter {
                title
                sidebars {
                  sidebar {
                    frontmatter {
                      title
                      bodycontent
                      dynamicsidebarid
                      renderashtml
                      outercontainercss
                      id
                    }
                  }
                }
              }
            }
          }
          bodycontent
          photogallery {
            frontmatter {
              title
              description
              images {
                imgtitle
                image {
                  childImageSharp {
                    fluid(maxWidth: 1600, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                    fixed(width: 135, height: 118, quality: 100) {
                      ...GatsbyImageSharpFixed_withWebp
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    headerImage: file(relativePath: { eq: "home.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
          src
        }
      }
    }
    teamImage: file(relativePath: { eq: "team-pic.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
          src
        }
      }
    }
  }
`;
